import {
  Box,
  Button,
  FormHelperText,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import lockIcon from "../../assets/PngImages/lock_icon.png";
import { BiLock } from "react-icons/bi";

const CardPaymentView = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [containValue, setContainValue] = useState([]);
  const [error, setError] = useState("");
  //   const handleSubmit = async (value?: string) => {
  //     try {
  //       if (stripe) {
  //         setLoading(true);
  //         if (dialogType === "addCard") {
  //           let data;
  //           if (elements) {
  //             data = await stripe.createPaymentMethod({
  //               type: "card",
  //               card: elements.getElement(
  //                 CardCvcElement,
  //                 CardExpiryElement,
  //                 CardNumberElement
  //               ),
  //               billing_details: {
  //                 name: user.firstName,
  //                 email: user?.email,
  //               },
  //             });
  //           }

  //           const error = data?.error;
  //           const paymentMethodId = data?.paymentMethod?.id || "";
  //           if (paymentMethodId) {
  //             await createPaymentCard({
  //               variables: {
  //                 createPaymentCardInput: {
  //                   paymentMethodId: paymentMethodId,
  //                   userId: user?._id,
  //                 },
  //               },
  //               onCompleted(data) {
  //                 setLoading(false);
  //                 enqueueSnackbar("Card Saved Successfully");
  //                 getAllCards();
  //                 setOpenCardDialog(false);
  //               },
  //               fetchPolicy: "no-cache",
  //               onError(error) {
  //                 setLoading(false);
  //                 getAllCards();
  //                 enqueueSnackbar("Unable to Save Card", { variant: "error" });
  //                 setOpenCardDialog(false);
  //               },
  //             });
  //           } else {
  //             setLoading(false);
  //             setOpenCardDialog(false);
  //             enqueueSnackbar("Unable to Save Card", { variant: "error" });
  //           }
  //           if (error) {
  //             setLoading(false);
  //             setOpenCardDialog(false);
  //             console.error("Error creating payment method:", error);
  //             enqueueSnackbar("The payment was unsuccessful.", {
  //               variant: "error",
  //             });
  //             enqueueSnackbar(error.message || "Unable to Save Card", {
  //               variant: "error",
  //             });
  //             setError(error.message || "");
  //           }
  //         } else {
  //           if (dialogType === "payment" || value === "payment") {
  //             console.log("here33", user);
  //             let paymentMethodId;
  //             let stripeCustomerId;
  //             if (selectedCard?.paymentMethodId) {
  //               paymentMethodId = selectedCard?.paymentMethodId;
  //             } else {
  //               let data;
  //               if (elements) {
  //                 data = await stripe.createPaymentMethod({
  //                   type: "card",
  //                   card: elements.getElement(
  //                     CardCvcElement,
  //                     CardExpiryElement,
  //                     CardNumberElement
  //                   ),
  //                   billing_details: {
  //                     name: user?.firstName,
  //                     email: user?.email,
  //                   },
  //                 });
  //               }
  //               paymentMethodId = data?.paymentMethod?.id || "";
  //               const error = data?.error;
  //               if (error) {
  //                 setLoading(false);
  //                 // setOpenCardDialog(false);
  //                 console.error("Error creating payment method:", error);
  //                 enqueueSnackbar("The payment was unsuccessful.", {
  //                   variant: "error",
  //                 });
  //                 enqueueSnackbar(error.message || "Unable to Process Payment", {
  //                   variant: "error",
  //                 });
  //                 setError(error.message || null);
  //               }
  //             }
  //             if (user?.stripeCustomerId || currentCustomerId) {
  //               stripeCustomerId = user?.stripeCustomerId || currentCustomerId;
  //             } else {
  //               await createCustomer({
  //                 variables: {
  //                   createCustomerInput: {
  //                     userId: user?._id,
  //                     email: user?.email,
  //                     name: `${user?.firstName}`,
  //                   },
  //                 },
  //                 fetchPolicy: "no-cache",
  //                 onCompleted(data) {
  //                   stripeCustomerId = data?.createCustomer?.stripeCustomerId;
  //                   let userData = {
  //                     ...user,
  //                     stripeCustomerId: data?.createCustomer?.stripeCustomerId,
  //                   };
  //                   setCurrentCustomerId(
  //                     data?.createCustomer?.stripeCustomerId || ""
  //                   );
  //                   updateUserData(userData);
  //                 },
  //                 onError(error) {
  //                   console.log(error);
  //                   setOpenCardDialog(false);
  //                   setLoading(false);
  //                 },
  //               });
  //             }
  //             if (stripeCustomerId && paymentMethodId) {
  //               let clientSecret;
  //               let reqData: CreateTaskPayment = {
  //                 paymentMethodId: paymentMethodId,
  //                 stripeCustomerId: stripeCustomerId,
  //                 amount: Number(oneTimePrice) + extraTaskPrice,
  //                 currency: "EUR",
  //                 buyerId: user?._id,
  //                 saveCardDetails: saveCard,
  //                 sellerId: allData?._id,
  //                 taskId:
  //                   bookingTaskData?.taskId ||
  //                   getCookie(`Booking_Form_Task_ID${user?._id}`),
  //                 // sellerEmail: user?.email,
  //                 // companyId: basicData?._id,
  //               };
  //               if (!basicData?.isCompany) {
  //                 reqData.sellerId = basicData?._id;
  //               } else {
  //                 reqData.companyId = basicData?._id;
  //               }
  //               // if (basicData?.sellersInfo?.companyId?._id) {
  //               //   reqData.companyId = basicData?.sellersInfo?.companyId?._id;
  //               // } else {
  //               //   if (basicData?._id) {
  //               //     reqData.sellerId = basicData?._id;
  //               //   }
  //               // }
  //               await makePayment({
  //                 variables: {
  //                   createTaskPayment: reqData,
  //                 },
  //                 fetchPolicy: "no-cache",
  //                 onCompleted(data) {
  //                   clientSecret = data?.createTaskPayment?.clientSecret;
  //                 },
  //                 onError(error) {
  //                   console.log(error);
  //                   setOpenCardDialog(false);
  //                   setLoading(false);
  //                 },
  //               });
  //               if (clientSecret) {
  //                 const confirm = await stripe?.confirmCardPayment(clientSecret);
  //                 // if (!confirm?.error) {
  //                 //   setTimeout(async () => {
  //                 //     const user = await getUserByToken();
  //                 //     updateLogin(true, user?.data?.data, socket);
  //                 //   }, 3000);
  //                 if (confirm?.error) {
  //                   enqueueSnackbar(confirm?.error?.message, {
  //                     variant: "error",
  //                   });
  //                   setOpenCardDialog(false);
  //                   setLoading(false);
  //                 } else {
  //                   enqueueSnackbar("The payment was successfully completed.");
  //                   dispatch(setTaskImages(""));

  //                   // await createBookingSlot({
  //                   //   variables: {
  //                   //     input: {
  //                   //       userId: basicData?._id,
  //                   //       taskId: allData?.task?._id,
  //                   //       date: bookingData?.selectedTaskTime,
  //                   //       startTime: bookingData?.selectedTaskTime,
  //                   //       endTime: addHoursToDate(
  //                   //         bookingData?.selectedTaskTime,
  //                   //         bookingData?.durationTime ? bookingData?.durationTime.toString() : '1'
  //                   //       ),
  //                   //     },
  //                   //   },
  //                   //   onCompleted() {
  //                   //     // handleNext();
  //                   //     // dialog3.onTrue();
  //                   //     enqueueSnackbar('Slot Booked successfully!!!');
  //                   //   },
  //                   // });
  //                   sendNotification({
  //                     message: "Your Got New Project.",
  //                     receiverId: basicData?._id,
  //                     type: "startedWork",
  //                     taskId: allData?.task?._id,
  //                     title: "New Project",
  //                     avatarUrl:
  //                       "https://cdn-icons-png.flaticon.com/512/3475/3475864.png",
  //                   });
  //                   setOpenCardDialog(false);
  //                   setLoading(false);
  //                   router.push("/booking");
  //                 }
  //               } else {
  //                 setLoading(false);
  //                 setOpenCardDialog(false);
  //                 enqueueSnackbar("Unable to Process Payment", {
  //                   variant: "error",
  //                 });
  //               }
  //             } else {
  //               setLoading(false);
  //               setOpenCardDialog(false);
  //               enqueueSnackbar("Unable to Process Payment", {
  //                 variant: "error",
  //               });
  //             }
  //           } else {
  //             setLoading(false);
  //             setOpenCardDialog(false);
  //             enqueueSnackbar("Unable to Process Payment", { variant: "error" });
  //           }
  //         }
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //       setOpenCardDialog(false);
  //     }
  //   };
  return (
    <Stack position={"relative"} mt={2}>
      <Stack>
        {/* Title with absolute positioning */}
        <Typography variant="caption" sx={{ fontSize: "12px", color: "#000" }}>
          Card Number
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          flexDirection={"column"}
          //   maxWidth={"316px"}
          //   width={"100%"}
          height={"49px"}
          px={2}
          sx={{ border: "1px solid #999999", borderRadius: "12px" }}
        >
          {/* <Box> */}
          <CardNumberElement
            options={{
              placeholder: "XXXX XXXX XXXX XXXX",
              style: {
                base: {
                  fontSize: "16px",
                  padding: "20px",

                  border: "1px solid #999999",
                  borderRadius: "8px",
                  height: "60px", // Set a fixed height
                  width: "100%",
                  "::placeholder": {
                    color: "#999999",
                    content: "XXXX XXXX XXXX XXXX",
                  },
                },
                invalid: {
                  color: "#9e2146",
                  border: "1px solid #9e2146", // Red border for invalid input
                },
              },
            }}
            onChange={(e) => {
              // console.log(e);
              // setCardBrand(e.brand);
              let arr = containValue;
              if (e.empty) {
                arr = arr.filter((val) => val !== "cardNumber");
                setContainValue(arr);
              } else {
                if (!arr.includes("cardNumber")) {
                  arr.push("cardNumber");
                  setContainValue(arr);
                }
              }
            }}
          />
          {/* </Box> */}
        </Stack>
        <FormHelperText sx={{ color: "#DC2626" }}>
          {error === "Your card number is incomplete." ? error : ""}
        </FormHelperText>
      </Stack>
      <Stack>
        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 size={{ sm: 6, xs: 12 }}>
            <Typography
              variant="caption"
              sx={{ fontSize: "12px", color: "#000" }}
            >
              Expiry Date
            </Typography>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              flexDirection={"column"}
              //   width={"100%"}
              //   maxWidth={"150px"}
              height={"49px"}
              px={2}
              sx={{ border: "1px solid #999999", borderRadius: "12px" }}
            >
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      padding: "14px",
                      border: "1px solid #999999", // Add a light gray border
                      borderRadius: "8px", // Rounded corners
                      "::placeholder": {
                        color: "#999999",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                      border: "1px solid #9e2146", // Red border for invalid input
                    },
                  },
                }}
                onChange={(e) => {
                  let arr = containValue;
                  if (e.empty) {
                    arr = arr.filter((val) => val !== "Date");
                    setContainValue(arr);
                  } else {
                    if (!arr.includes("Date")) {
                      arr.push("Date");
                      setContainValue(arr);
                    }
                  }
                }}
              />
            </Stack>
            <FormHelperText sx={{ color: "#DC2626" }}>
              {error === "Your card's expiration date is incomplete."
                ? error
                : ""}
            </FormHelperText>
          </Grid2>
          <Grid2 size={{ sm: 6, xs: 12 }}>
            {/* Title with absolute positioning */}
            <Typography
              variant="caption"
              sx={{ fontSize: "12px", color: "#000" }}
            >
              CVV
            </Typography>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              flexDirection={"column"}
              //   maxWidth={"150px"}
              //   width={"100%"}
              height={"49px"}
              px={2}
              sx={{ border: "1px solid #999999", borderRadius: "12px" }}
            >
              <CardCvcElement
                options={{
                  placeholder: "CVV",
                  style: {
                    base: {
                      fontSize: "16px",
                      padding: "14px",
                      border: "1px solid #999999", // Add a light gray border
                      borderRadius: "8px", // Rounded corners
                      "::placeholder": {
                        color: "#999999",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                      border: "1px solid #9e2146", // Red border for invalid input
                    },
                  },
                }}
                onChange={(e) => {
                  let arr = containValue;
                  if (e.empty) {
                    arr = arr.filter((val) => val !== "cvv");
                    setContainValue(arr);
                  } else {
                    if (!arr.includes("cvv")) {
                      arr.push("cvv");
                      setContainValue(arr);
                    }
                  }
                }}
              />
            </Stack>
            <FormHelperText sx={{ color: "#DC2626" }}>
              {error === "Your card's security code is incomplete."
                ? error
                : ""}
            </FormHelperText>
          </Grid2>
        </Grid2>
        <Button
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: "#00A372",
            borderRadius: "15px",
            height: "58px",
          }}
        >
          <Stack direction={"row"}>
            <Stack mr={0.3} direction={"row"} alignItems={"center"}>
              <BiLock size={"24px"} />
              {/* <img
                src={lockIcon}
                alt="lock"
                style={{ width: "20px", height: "auto" }}
              /> */}
            </Stack>
            <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
              Continue
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </Stack>
  );
};

export default CardPaymentView;
