import React, { createContext, useState, useEffect } from "react";
import { getCookie, setCookie } from "../Utils/cookie";
import { useLocation } from "react-router-dom";

// Create context
export const GenderContext = createContext();

// Create a provider component
export const GenderThemeProvider = ({ children }) => {
  // Initialize gender from localStorage or set default
  const [gender, setGender] = useState(() => {
    const savedData = getCookie("data");
    return savedData?.gender || ""; // Default to "male" if not available
  });

  // Update localStorage when gender changes
  useEffect(() => {
    const data = getCookie("data") || {};
    data.gender = gender;
    setCookie("data", data, window.location.pathname);
  }, [gender]);

  return (
    <GenderContext.Provider value={{ gender, setGender }}>
      {children}
    </GenderContext.Provider>
  );
};
