import React, { useState } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import paypalLogo from "../../assets/PngImages/paypal_logo.png";
import {
  Snackbar,
  SnackbarContent,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getCookie, setCookie } from "../../Utils/cookie";

const PaypalOption = ({ price, goToNextSlide }) => {
  const theme = useTheme();
  const [open, setOpen] = useState("");
  const handleClose = () => {
    setOpen("");
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY, // Replace with your client ID
    currency: "USD",
    intent: "capture",
  };

  return (
    <Stack position={"relative"}>
      <style>{`.paypal-button{
  border-radius: 40px;
  width: ${isSmallScreen ? "220px" : "290px"};
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
}`}</style>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor:
              open === "Payment Successful"
                ? theme.palette.success.main
                : theme.palette.error.main,
          }} // Change to your desired success color
          message={open}
        />
      </Snackbar>
      <PayPalScriptProvider options={initialOptions}>
        <Stack
          position={"absolute"}
          zIndex={999}
          left={{ sm: 62, xs: 35 }}
          top={4}
          width={"40px"}
        >
          <img
            src={paypalLogo}
            alt="logo"
            style={{ width: "100%", height: "auto" }}
          />
        </Stack>
        <PayPalButtons
          createOrder={(data, actions) => {
            // Define your order creation logic here
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: parseFloat(price.replace("$", "")), // Example amount
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            // Define your order approval logic here
            return actions.order.capture().then((details) => {
              console.log("Order captured:", details);
              setOpen(
                details?.status === "COMPLETED"
                  ? "Payment Successful"
                  : "Payment Failed"
              );
              let data = getCookie("data");
              data.paymentDetails = details;
              setCookie("data", data);
              setTimeout(() => {
                if (details?.status === "COMPLETED") goToNextSlide();
              }, [1000]);
            });
          }}
          className="paypal-button"
          fundingSource={FUNDING.PAYPAL}
          style={{ height: 55, borderRadius: 40, layout: "vertical" }}
        />
      </PayPalScriptProvider>
    </Stack>
  );
};

export default PaypalOption;
