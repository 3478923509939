export const ChangeTheme = (gender) => {
  // const { gender } = JSON.parse(localStorage.getItem("data")) || {}; // Getting gender from context

  if (gender === "female") {
    document.body.style.backgroundColor = "#ffffff";
    document.documentElement.style.setProperty(
      "--primary-button-color",
      "#ff0099"
    );
    document.documentElement.style.setProperty(
      "--primary-text-color",
      "#141432"
    );
    document.documentElement.style.setProperty("--text-color2", "#ff0099");
    document.documentElement.style.setProperty(
      "--secondary-background-color",
      "#fefefe"
    );
    document.documentElement.style.setProperty("--border", "#ff0099");
    document.documentElement.style.setProperty("--frame-background", "#f5f5f5");
    document.documentElement.style.setProperty("--selected-button", "#ffbbe4");
    document.documentElement.style.setProperty(
      "--selected-button-text",
      "#ffbbe4"
    );
    document.documentElement.style.setProperty(
      "--chart-background-color",
      "#f5f5f5"
    );
    document.documentElement.style.setProperty("--progressed-color", "#d80182");
    document.documentElement.style.setProperty(
      "--remaining-progress-color",
      "#e0e0e0"
    );
    document.documentElement.style.setProperty("--image-border", "#fe15a1");
    document.documentElement.style.setProperty("--card-color", "#f5f5f5");
    document.documentElement.style.setProperty("--card2-color", "#ffffff");
    document.documentElement.style.setProperty(
      "--price-card-background",
      "#f5f5f5"
    );
    document.documentElement.style.setProperty(
      "--selected-price-card-background",
      "#ffddf1"
    );
    document.documentElement.style.setProperty(
      "--selected-price-card-border",
      "#ff37af"
    );
  } else {
    // Initial values
    document.body.style.backgroundColor = "#191b3d";

    document.documentElement.style.setProperty(
      "--primary-button-color",
      "#006bff"
    );
    document.documentElement.style.setProperty("--primary-text-color", "white");
    document.documentElement.style.setProperty("--text-color2", "#4dd0e1");
    document.documentElement.style.setProperty(
      "--secondary-background-color",
      "#141432"
    );
    document.documentElement.style.setProperty("--border", "#007aff");
    document.documentElement.style.setProperty("--frame-background", "#262846");
    document.documentElement.style.setProperty("--selected-button", "#007aff");
    document.documentElement.style.setProperty(
      "--selected-button-text",
      "#ffbbe4"
    );
    document.documentElement.style.setProperty(
      "--chart-background-color",
      "#141432"
    );
    document.documentElement.style.setProperty("--progressed-color", "#0150b6");
    document.documentElement.style.setProperty(
      "--remaining-progress-color",
      "#414052"
    );
    document.documentElement.style.setProperty("--image-border", "#288fff");
    document.documentElement.style.setProperty("--card-color", "#141432");
    document.documentElement.style.setProperty("--card2-color", "#191b3d");
    document.documentElement.style.setProperty(
      "--price-card-background",
      "#262846"
    );
    document.documentElement.style.setProperty(
      "--selected-price-card-background",
      "#162450"
    );
    document.documentElement.style.setProperty(
      "--selected-price-card-border",
      "#036ee7"
    );
  }
};
