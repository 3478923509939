import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardPaymentView from "./CardPaymentView";
import { Box, Stack } from "@mui/material";
import visaImage from "../../assets/PngImages/visa_logo_lg.png";
import masterImage from "../../assets/PngImages/master_logo_big.png";
import mestroImage from "../../assets/PngImages/mestro_logo_big.png";
import discoverImage from "../../assets/PngImages/discover_logo_big.png";
import amexImage from "../../assets/PngImages/amex_logo_big.png";

const StripeMainView = () => {
  const key = process.env.REACT_APP__STRIPE_CLIENT_KEY || "";
  console.log(process.env.REACT_APP__STRIPE_CLIENT_KEY);
  const stripePromise = loadStripe(key);
  return (
    <Box>
      <Stack
        mt={1}
        direction={"row"}
        width={"100%"}
        justifyContent={"center"}
        flexWrap={"wrap"}
      >
        <img alt="visa" src={visaImage} />
        <img alt="master" src={masterImage} />
        <img alt="mestro" src={mestroImage} />
        <img alt="discover" src={discoverImage} />
        <img alt="amex" src={amexImage} />
      </Stack>
      <Elements stripe={stripePromise}>
        <CardPaymentView />
      </Elements>
    </Box>
  );
};

export default StripeMainView;
