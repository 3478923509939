import { Box } from "@mui/material";

import Loader from "../assets/Images/loader.gif";

const LoaderGif = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <img src={Loader} alt="Loading..." style={{ width: "150px" }} />
    </Box>
  );
};

export default LoaderGif;
